<template>
  <el-container>
    <el-main>
      <el-row style="width: 100%">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          @click="refreshFlows"
          plain
          size="small"
          :loading="loadingFlows"
        >
          Refresh
        </el-button>
        <el-dropdown>
          <el-button style="margin-left: 10px;" type="primary" size="small">
            Create New Flow<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-plus" @click.native="openCreateModal()">Create New</el-dropdown-item>
            <el-dropdown-item icon="el-icon-magic-stick">Import from KeyReply Form Module</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
      <el-row style="width: 100%">
        <el-table
          :data="flowsData"
          style="width: 100%"
          v-loading="loadingFlows"
          element-loading-text="Loading..."
          element-loading-spinner="el-icon-loading"
        >
          <el-table-column
            prop="name"
            label="Flow Name"
          >
          </el-table-column>
          <el-table-column
            prop="id"
            label="Flow ID">
            <template slot-scope="scope">
              {{scope.row.id}}
              <el-tooltip content="Copy" placement="top">
                <el-button size="mini" @click="copyText(scope.row.id)" icon="el-icon-document-copy" plain circle></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="Status">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status === 'PUBLISHED'" type="success" size="mini">Published</el-tag>
              <el-tag v-else-if="scope.row.status === 'DRAFT'" type="warning" size="mini">Draft</el-tag>
              <el-tag v-else-if="scope.row.status === 'DEPRECATED'" type="danger" size="mini">Deprecated</el-tag>
              <el-tag v-else type="info" size="mini">Unknown</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Actions">
            <template slot-scope="scope">
              <el-dropdown>
                <el-button size="mini">
                  More<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-view" @click.native="openFullScreenModal(scope.row)">Preview</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-copy-document" @click.native="openCreateModal(scope.row, { clone: true })">Clone</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.status === 'PUBLISHED'" icon="el-icon-delete" @click.native="deprecateFlow(scope.row)">Deprecate</el-dropdown-item>
                  <el-dropdown-item v-else-if="scope.row.status === 'DRAFT'" icon="el-icon-delete" @click.native="deleteFlow(scope.row)">Delete</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.status === 'DRAFT'" icon="el-icon-upload2" @click.native="publishFlow(scope.row)">Publish</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalFlows">
          </el-pagination>
        </div>
        <el-dialog
          :visible.sync="createDialogVisible"
          width="80%"
          :before-close="closeCreateModal"
        >
          <h3>{{formTitle}}</h3>
          <p>{{formDescription}}</p>
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="Name">
              <el-input
                v-model="form.name"
                :maxlength="200"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="Categories">
              <el-select v-model="form.categories" multiple>
                <el-option
                  v-for="category in CATEGORIES"
                  :key="category.value"
                  :label="category.label"
                  :value="category.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" :loading="loadingFlows" @click="submitForm">Submit</el-button>
            <el-button @click="closeCreateModal">Cancel</el-button>
          </span>
        </el-dialog>
        <el-dialog
          :visible.sync="fullscreenDialogVisible"
          :fullscreen="true"
        >
          <FlowBuilder
            v-if="fullscreenDialogVisible"
            :flow="flow"
            @close="closeFullscreenModal"
            @openCreateModal="openCreateModal($event.flow, { clone: $event.clone })"
          />
        </el-dialog>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import FlowBuilder from './FlowBuilder.vue';

export default {
  name: "FlowsTab",
  components: {
    FlowBuilder,
  },
  data() {
    return {
      loadingFlows: false,
      currentPage: 1,
      pageSize: 10,
      createDialogVisible: false,
      currentFlowName: "",
      form: {
        id: "",
        name: "",
        categories: [],
      },
      fullscreenDialogVisible: false,
      flow: {},

      // constants.
      CATEGORIES: [
        { label: "Sign up", value: "SIGN_UP" },
        { label: "Sign in", value: "SIGN_IN" },
        { label: "Appointment booking", value: "APPOINTMENT_BOOKING" },
        { label: "Lead generation", value: "LEAD_GENERATION" },
        { label: "Shopping", value: "SHOPPING" },
        { label: "Contact us", value: "CONTACT_US" },
        { label: "Customer support", value: "CUSTOMER_SUPPORT" },
        { label: "Survey", value: "SURVEY" },
        { label: "Other", value: "OTHER" },
      ],
    };
  },
  methods: {
    copyText(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$message({
          message: 'Copied to clipboard',
          type: 'success'
        });
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },
    openCreateModal(row, { clone } = { clone: false }) {
      if (clone) {
        this.currentFlowName = row?.name;
        this.form.id = row?.id;
        this.form.name = row?.name + " clone";
        this.form.categories = row?.categories;
      }
      this.createDialogVisible = true;
    },
    closeCreateModal() {
      this.createDialogVisible = false;
      this.currentFlowName = "";
      this.form.id = "";
      this.form.name = "";
      this.form.categories = [];
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.loadingFlows = true;
      this.$store.dispatch("GET_FLOWS", {
        data: {
          offset: 0,
          limit: size,
        },
        refresh: false,
      })
        .finally(() => {
          this.loadingFlows = false;
        });
    },
    handleCurrentChange(currentPage) {
      this.loadingFlows = true;
      this.$store.dispatch("GET_FLOWS", {
        data: {
          offset: (currentPage - 1) * this.pageSize,
          limit: this.pageSize,
        },
        refresh: false,
      })
        .finally(() => {
          this.loadingFlows = false;
        });
    },
    refreshFlows() {
      this.loadingFlows = true;
      this.$store.dispatch("GET_FLOWS", {
        data: {
          limit: this.pageSize,
        },
        refresh: true,
      })
        .finally(() => {
          this.loadingFlows = false;
          this.currentPage = 1;
        });
    },
    submitForm() {
      const payload = {
        name: this.form.name,
        categories: this.form.categories,
      }
      const cloning = !!this.form.id;
      if (cloning) {
        payload.clone_flow_id = this.form.id;
      }
      this.loadingFlows = true;
      this.$store.dispatch("SUBMIT_FLOW", payload)
        .then(() => {
          this.closeCreateModal();
          this.refreshFlows();
        })
        .catch((err) => {
          this.loadingFlows = false;
          this.$notify.error({
            message: err?.response?.data?.message || "Internal server error. Please contact support.",
            title: err?.response?.data?.title || "Error",
            position: "bottom-right",
          });
        });
    },
    deleteFlow(row) {
      this.$confirm(
        `Are you sure you want to delete the flow "${row.name}"?`,
        "Delete Flow",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning"
        }).then(() => {
          this.loadingFlows = true;
          this.$store.dispatch("DELETE_WHATSAPP_FLOW", { id: row.id })
            .then(() => {
              this.$notify.success({
                message: "Flow successfully deleted.",
                title: "Success",
                position: "bottom-right",
              });
              this.refreshFlows();
            })
            .catch((err) => {
              this.loadingFlows = false;
              this.$notify.error({
                message: err?.response?.data?.message || "Internal server error. Please contact support.",
                title: err?.response?.data?.title || "Error",
                position: "bottom-right",
              });
            });
        }).catch(() => {
          this.$message.info("Deletion cancelled.");
        });
    },
    deprecateFlow(row) {
      this.$confirm(
        `Are you sure you want to deprecate the flow "${row.name}"?`,
        "Deprecate Flow",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning"
        }).then(() => {
          this.loadingFlows = true;
          this.$store.dispatch("DEPRECATE_WHATSAPP_FLOW", { id: row.id })
            .then(() => {
              this.$notify.success({
                message: "Flow successfully deprecated.",
                title: "Success",
                position: "bottom-right",
              });
              this.refreshFlows();
            })
            .catch((err) => {
              this.loadingFlows = false;
              this.$notify.error({
                message: err?.response?.data?.message || "Internal server error. Please contact support.",
                title: err?.response?.data?.title || "Error",
                position: "bottom-right",
              });
            });
        }).catch(() => {
          this.$message.info("Deprecation cancelled.");
        });
    },
    publishFlow(row) {
      this.$confirm(
        `Are you sure you want to publish the flow "${row.name}"?`,
        "Publish Flow",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning"
        }).then(() => {
          this.loadingFlows = true;
          this.$store.dispatch("PUBLISH_WHATSAPP_FLOW", { id: row.id })
            .then(() => {
              this.$notify.success({
                message: "Flow successfully published.",
                title: "Success",
                position: "bottom-right",
              });
              this.refreshFlows();
            })
            .catch((err) => {
              this.loadingFlows = false;
              this.$notify.error({
                message: err?.response?.data?.message || "Internal server error. Please contact support.",
                title: err?.response?.data?.title || "Error",
                position: "bottom-right",
              });
            });
        }).catch(() => {
          this.$message.info("Publishing cancelled.");
        });
    },
    openFullScreenModal(row) {
      this.fullscreenDialogVisible = true;
      this.flow = row;
    },
    closeFullscreenModal() {
      this.fullscreenDialogVisible = false;
      this.$store.commit("SET_SELECTED_FLOW"); // reset selectedFlow
    },
  },
  computed: {
    flowsData() {
      return this.$store.state.whatsapp?.flows?.data || [];
    },
    totalFlows() {
      return this.$store.state.whatsapp?.flows?.total || 0;
    },
    formTitle() {
      return !this.form.id ? "Create new flow" : "Clone flow"
    },
    formDescription() {
      return !this.form.id ? "You are creating new flow in this dialog" : `You are cloning the ${this.currentFlowName} flow in this dialog`
    },
  },
  created() {
    this.loadingFlows = true;
    this.$store.dispatch("GET_FLOWS", {
      data: {},
      refresh: false,
    })
      .finally(() => {
        this.loadingFlows = false;
      });
  }
};
</script>

<style scoped>
/* Your styles here */
.pagination {
  margin-top: 14px;
}

</style>
