<template>
  <el-drawer
    :visible.sync="isPreviewOpen"
    :with-header="false"
    direction="rtl"
    id="flow-editor-preview-drawer"
    @closed="onClosed"
    :size="activeFlow && activeFlow?.mode === 'whatsapp' ? '45vw' : undefined"
  >
    <div v-if="activeFlow && activeFlow?.mode === 'whatsapp'">
      <iframe
        ref="previewIframe"
        style="width: 100%; height: 100vh; border: none;"
        :src="waFlowPreviewURL">
      </iframe>
    </div>
    <div style="padding: 15px" v-else-if="activeFlow && activeFlow?.mode !== 'whatsapp'">
      <PreviewHeader
        slot="title"
        :active-flow-title="activeFlow.title"
        @closePreview="isPreviewOpen = false"
        @startFlow="startFlow"
      />
      <br />

      <div style="display: flex">
        <el-select
          size="mini"
          filterable
          v-model="sourceType"
          placeholder="Select a Source"
          style="margin-right: 10px"
          @change="startFlow"
        >
          <el-option
            v-for="item in this.sources"
            :key="item"
            :label="capitalize(item)"
            :value="item"
          />
        </el-select>

        <el-select
          filterable
          size="mini"
          v-model="sectionId"
          placeholder="Select a flow"
          @change="startFlow"
        >
          <el-option
            v-for="item in activeFlow.sections"
            :key="item.sectionId"
            :label="item.title"
            :value="item.sectionId"
          />
        </el-select>
      </div>

      <el-divider>Messages</el-divider>

      <PreviewWindow
        :messages="messages"
        :flow-type="activeFlow.flowType"
        @reply="reply"
        style="overflow-y: hidden"
        @sendPostback="onPostback"
      />
    </div>
    <div style="padding: 15px" v-else>
      <h3>Please select a flow to preview!</h3>
    </div>
  </el-drawer>
</template>
<script>
import PreviewHeader from "./PreviewHeader";
import PreviewWindow from "./PreviewWindow";
import { v4 as uuid } from "uuid";
import { capitalize, get } from "lodash";

export default {
  components: {
    PreviewHeader,
    PreviewWindow,
  },
  props: ["activeFlow"],
  mounted() {
    if (this.activeFlow?.mode !== "whatsapp") {
      return;
    }
    this.retrieveWAFlowPreviewURL();
  },
  watch: {
    isPreviewOpen(newVal) {
      if (!newVal || this.activeFlow?.mode !== "whatsapp") {
        return;
      }
      this.retrieveWAFlowPreviewURL();
    },
  },
  data() {
    return {
      isPreviewOpen: false,
      messages: [],
      replyMessage: "",
      sectionId: null,
      sourceType: "",
      senderId: "",
      recipientId: "",
      waFlowPreviewURL: "",
    };
  },
  computed: {
    sources: {
      get() {
        const sources = [];
        if (this.$store.state.modules.twilio.enabled) {
          sources.push("twilio");
        }

        if (this.$store.state.modules.webchat.enabled) {
          sources.push("webchat");
        }
        return sources;
      },
    },
  },
  methods: {
    capitalize,
    retrieveWAFlowPreviewURL() {
      if (!this.activeFlow?.waFlowId) {
        return;
      }
      this.$store.dispatch("GET_FLOW_PREVIEW_URL", { data: { id: this.activeFlow.waFlowId } })
        .then((data) => {
          this.waFlowPreviewURL = data?.preview?.preview_url;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Failed to fetch the flow preview, please refresh the page.",
          });
        });
    },
    onClosed() {
      this.messages = [];
      this.sectionId = "";
      this.sourceType = "";
      this.senderId = "";
      this.recipientId = "";
    },
    startFlow() {
      this.senderId = get(this, "$store.state.profile.email");
      this.recipientId = "preview";
      let { sectionId, sourceType } = this;
      this.messages = [];

      if (sectionId === "") {
        return;
      }

      this.$store
        .dispatch("PREVIEW_FLOW", {
          sectionId,
          sourceType,
          senderId: this.senderId,
          recipientId: this.recipientId,
        })
        .then((response) => {
          const responseArray = get(response, "response", []);
          responseArray.forEach((response) => {
            const text = get(response, "content[0].text");
            if (sourceType === "twilio") {
              this.messages.push({
                data: {
                  id: uuid(),
                  content: [
                    {
                      text,
                    },
                  ],
                },
                type: "message",
              });
            } else if (sourceType === "webchat") {
              this.messages.push({
                data: {
                  id: uuid(),
                  content: [
                    {
                      text,
                    },
                  ],
                  quickReplies: get(response, "quickReplies"),
                },
                type: "message",
              });
            }
          });
        });
    },
    reply(message) {
      this.messages.push({
        data: {
          id: uuid(),
          content: [
            {
              text: message,
            },
          ],
        },
        type: "reply",
      });
      const { sourceType, senderId, recipientId } = this;
      this.$store
        .dispatch("PREVIEW_REPLY", {
          message,
          sourceType,
          senderId,
          recipientId,
        })
        .then((response) => {
          if (sourceType === "twilio") {
            // response follows twilio's api response structure
            this.messages.push({
              data: {
                id: uuid(),
                content: [
                  {
                    text: get(response, "response.text", ""),
                  },
                ],
              },
              type: "message",
            });
          } else if (sourceType === "webchat") {
            // response follows webchat's api response structure
            if (Array.isArray(response)) {
              for (const message of response) {
                if (message.content) {
                  this.messages.push({
                    data: {
                      id: uuid(),
                      content: message.content,
                      quickReplies: response.quickReplies,
                    },
                    type: "message",
                  });
                }
              }
            } else {
              this.messages.push({
                data: {
                  id: uuid(),
                  content: response.content,
                  quickReplies: response.quickReplies,
                },
                type: "message",
              });
            }
          }
        });
    },
    onPostback(event) {
      this.messages.push({
        data: {
          id: uuid(),
          content: [
            {
              text: event.text,
            },
          ],
        },
        type: "reply",
      });
      const { sourceType, senderId, recipientId } = this;
      this.$store
        .dispatch("PREVIEW_REPLY", {
          sourceType,
          postback: event,
          senderId,
          recipientId,
        })
        .then((response) => {
          if (response.attachment) {
            this.messages.push({
              data: {
                id: uuid(),
                attachment: response.attachment,
              },
              type: "message",
            });
          } else if (Array.isArray(response)) {
            for (const message of response) {
              if (message.content) {
                this.messages.push({
                  data: {
                    id: uuid(),
                    content: message.content,
                    quickReplies: response.quickReplies,
                  },
                  type: "message",
                });
              } else if (message.attachment) {
                this.messages.push({
                  data: {
                    id: uuid(),
                    attachment: response.attachment,
                  },
                  type: "message",
                });
              }
            }
          } else {
            this.messages.push({
              data: {
                id: uuid(),
                content: response.content,
                quickReplies: response.quickReplies,
              },
              type: "message",
            });
          }
        });
    },
  },
};
</script>

<style lang="css" scoped>
.el-scrollbar ul {
  width: 100%;
}

#flow-editor-preview-drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
</style>
