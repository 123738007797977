<template>
  <el-row v-if="activeFlow" style="margin-bottom: 0" :gutter="20">
    <el-col :xs="24" :md="8" :lg="6">
      <el-card class="flow-editor-list flow-editor-panel">
        <div
          @click.prevent="onTabChanged({ name: 'config-default' })"
          :class="{ 'is-current': currentTab === 'section-config-default' }"
          class="flow-editor-list-item"
        >
          CONFIG - General
        </div>

        <div
          class="flow-editor-list-item"
          v-for="(section, index) in activeFlow.sections"
          v-dragging="{
            item: section,
            list: activeFlow.sections,
            group: 'flow_sections',
          }"
          :class="{
            'is-current': currentTab === `section-${section.sectionId}`,
          }"
          :key="`${activeFlow.id}-section-${index}`"
          @click.prevent="onTabChanged({ name: section.sectionId })"
        >
          {{ section.title }}
        </div>

        <div
          @click.prevent="onTabChanged({ name: 'config-end-flow' })"
          :class="{ 'is-current': currentTab === 'section-config-end-flow' }"
          class="flow-editor-list-item"
        >
          CONFIG - End Flow
        </div>
      </el-card>
    </el-col>
    <el-col :xs="24" :md="16" :lg="18">
      <el-card class="flow-editor-view flow-editor-panel">
        <template v-if="currentTab === 'section-config-default'">
          <ConfigDefault
            :allFlowsNameArray="allFlowsNameArray"
            :resetOptions="resetOptions"
            :resetDescription="resetDescription"
            :cancelOptions="cancelOptions"
            :active-flow="activeFlow"
          />
        </template>
        <template v-else-if="currentTab === 'section-config-end-flow'">
          <ConfigEndFlow :active-flow="activeFlow" />
        </template>
        <template v-else>
          <div
            v-for="(section, index) in activeFlow.sections"
            :key="`${activeFlow.id}-section-${index}`"
          >
            <template v-if="currentTab === `section-${section.sectionId}`">
              <el-row type="flex" :gutter="0" justify="space-between" class="flow-section-title">
                <el-input v-model="section.title" style="margin-bottom: 10px;">
                  <div slot="prepend">Section Title</div>
                  <div slot="append" style="cursor: pointer">
                    <i v-copy="{ text: section.title }" class="el-icon-document-copy"></i>
                  </div>
                </el-input>

                <el-row>
                  <SectionQuestionAdd
                    ref="section-question-add"
                    :active-flow="activeFlow"
                    :section="section"
                  />

                  <SectionDuplicate
                    :active-flow="activeFlow"
                    :section="section"
                    :flow-options="flowOptions"
                  />

                  <el-button
                    plain
                    style="margin-left: 10px"
                    size="mini"
                    type="warning"
                    icon="el-icon-delete"
                    @click.prevent="onDeleteSection(index)"
                  >
                    Delete Section
                  </el-button>
                </el-row>
              </el-row>
              <SectionQuestions
                :flow-options="flowOptions"
                :active-flow="activeFlow"
                :section="section"
              />
            </template>
          </div>
        </template>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import ConfigDefault from "./ConfigDefault";
import ConfigEndFlow from "./ConfigEndFlow";
import SectionQuestionAdd from "./Questions/Add";
import SectionQuestions from "./Questions/Index.vue";
import SectionDuplicate from "./Duplicate";

export default {
  data() {
    return {
      currentTab: null,
    };
  },
  components: {
    ConfigDefault,
    ConfigEndFlow,
    SectionQuestions,
    SectionQuestionAdd,
    SectionDuplicate,
  },
  props: [
    "activeFlow",
    "allFlowsNameArray",
    "cancelOptions",
    "resetDescription",
    "resetOptions",
    "arrayFlows",
  ],
  mounted() {
    this.currentTab = "section-config-default";

    this.$eventBus.$on("setSelectedSection", (sectionId) => {
      this.currentTab = sectionId;
    });
  },
  computed: {
    flowOptions() {
      return this.arrayFlows.map((flow) => {
        return {
          label: `flow - ${flow.title.toLowerCase()}`,
          value: flow.flowId,
          children: [
            ...flow.sections.map((section) => {
              return {
                label: `section - ${section.title.toLowerCase()}`,
                value: section.sectionId,
                disabled: _.includes(this.currentTab, section.sectionId),
              };
            }),
          ],
        };
      });
    },
  },
  methods: {
    onTabChanged(event) {
      let sectionId = null;
      if (_.includes(event.name, "config")) {
        sectionId = event.name.replace(`tab-${this.activeFlow.flowId}-`, "");
      } else {
        sectionId = event.name.replace(`tab-${this.activeFlow.flowId}-section-`, "");
      }
      this.currentTab = `section-${sectionId}`;
      this.$eventBus.$emit("setHeaderFilter", [this.activeFlow.flowId, sectionId]);
    },

    /**
     * @description On delete selected section confirmation
     * @return {void}
     */
    onDeleteSection(index) {
      this.$confirm(`Are you sure to delete the section?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      })
        .then(() => this.deleteSection(index))
        .catch(() => {});
    },

    /**
     * @description Delete a section
     * @return {void}
     */
    deleteSection(index) {
      this.activeFlow.sections.splice(index, 1);
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1280px) {
  .flow-section-title {
    flex-wrap: wrap;
  }
}
</style>
