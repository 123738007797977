<template>
  <el-form :model="activeFlow" label-width="200px">
    <el-divider>General Flow Settings</el-divider>
    <el-form-item>
      <label slot="label" style="text-align: left">
        Next button label text
      </label>
      <el-input
        v-model="activeFlow.nextButtonLabel"
        placeholder="Enter text"
        :maxlength="30"
        show-word-limit
      ></el-input>
    </el-form-item>
    <el-form-item>
      <label slot="label" style="text-align: left">
        Finish button label text
      </label>
      <el-input
        v-model="activeFlow.finishButtonLabel"
        placeholder="Enter text"
        :maxlength="30"
        show-word-limit
      ></el-input>
    </el-form-item>
    <el-form-item label="Categories">
      <el-select v-model="activeFlow.categories" multiple>
        <el-option
          v-for="category in CATEGORIES"
          :key="category.value"
          :label="category.label"
          :value="category.value"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: ["activeFlow"],
  components: {},
  computed: {},
  data() {
    return {
      CATEGORIES: [
        { label: "Sign up", value: "SIGN_UP" },
        { label: "Sign in", value: "SIGN_IN" },
        { label: "Appointment booking", value: "APPOINTMENT_BOOKING" },
        { label: "Lead generation", value: "LEAD_GENERATION" },
        { label: "Shopping", value: "SHOPPING" },
        { label: "Contact us", value: "CONTACT_US" },
        { label: "Customer support", value: "CUSTOMER_SUPPORT" },
        { label: "Survey", value: "SURVEY" },
        { label: "Other", value: "OTHER" },
      ],
    };
  },
};
</script>
